
<template>
  <div>
    <PriorityNavigation class="d-flex align-items-center dataview-action-bar time-toolbar" v-if="allowManage && datesMode !== 'current' && !isWidget">
      <li>
        <span v-if="!epoch && startDate && endDate" class="d-flex ml-2 mr-2">
          <span class="mt-1 mr-1">{{ $t('dataview.chart.data') }}</span>
          <multiselect v-model="timeseries_field" class="custom-dropdown-options dataview-bar enable-option-icon fit-label-fix fit-content-fix"
            :max-height="300"
            :options="numberFieldOptions.map(i => i.value)"
            :custom-label="getNumberFieldOptionLabel"
            :placeholder="''"
            :searchable="false" 
            :allow-empty="false"
            :showLabels="false"
            @input="fieldSelected">
            <template slot="option" slot-scope="props">
              <font-awesome-icon class="selected-option-icon" v-if="timeseries_field == props.option" :icon="['far', 'check']" />
              <span class="option__title">{{ getNumberFieldOptionLabel(props.option) }}</span>
            </template>
          </multiselect>
        </span>
      </li>
      <li v-if="!epoch">
        <span readonly class="action-v-divider" v-if="editPermission">|</span>
      </li>
      <li>
        <span v-if="epoch && startDate && endDate" class="d-flex align-items-center ml-2 mr-2">
          <label class="mr-1">{{ spanPrefix }}</label>
          <b-btn :disabled="options === null || disableEpochLeft" :id="`BTN_LEFT_EPOCH_CHART`" @click="leftEpoch"><font-awesome-icon :icon="['far','chevron-circle-left']"/>
            <b-popover
             :target="`BTN_LEFT_EPOCH_CHART`"
               placement="top"
               boundary="viewport"
               triggers="hover"
             :content="$t('dataview.previous')">
            </b-popover>
          </b-btn>
          <multiselect v-model="epoch" class="custom-dropdown-options dataview-bar enable-option-icon fit-label-fix fit-content-fix"
            :max-height="300"
            :options="epochOptions.map(i => i.value)"
            :custom-label="getEpochOptionLabel"
            :placeholder="''"
            :searchable="false" 
            :allow-empty="false"
            :showLabels="false"
            @input="epochSelected">
            <template slot="option" slot-scope="props">
              <font-awesome-icon class="selected-option-icon" v-if="epoch == props.option" :icon="['far', 'check']" />
              <span class="option__title">{{ getEpochOptionLabel(props.option) }}</span>
            </template>
          </multiselect>
          <b-btn :disabled="options === null || disableEpochRight" :id="`BTN_RIGHT_EPOCH_CHART`" @click="rightEpoch"><font-awesome-icon :icon="['far','chevron-circle-right']"/>
            <b-popover
             :target="`BTN_RIGHT_EPOCH_CHART`"
               placement="top"
               boundary="viewport"
               triggers="hover"
             :content="$t('dataview.next')">
            </b-popover>
          </b-btn>
        </span>
      </li>
      <li>
        <span class="d-flex align-items-center ml-2 mr-2">
          <label class="mr-1" for="dates">{{ $t('staff.dates') }}</label>
          <multiselect v-model="datesStr" class="custom-dropdown-options dataview-bar enable-option-icon fit-label-fix fit-content-fix"
            :max-height="300"
            :options="dateOptions.map(i => i.value)"
            :custom-label="getDateOptionLabel"
            :placeholder="''"
            :searchable="false" 
            :allow-empty="false"
            :showLabels="false"
            @input="rangeSelected">
            <template slot="option" slot-scope="props">
              <font-awesome-icon class="selected-option-icon" v-if="datesStr == props.option" :icon="['far', 'check']" />
              <span class="option__title">{{ getDateOptionLabel(props.option) }}</span>
            </template>
          </multiselect>
        </span>
      </li>
      <li>
        <span class="d-flex align-items-center mr-1 date">
          <label class="mr-1" for="startDate">{{ $t('staff.from') }}</label>
          <b-form-datepicker id="chartStartDate" v-model="startDate" class="d-flex" @input="dateChanged()"
              today-button
              reset-button
              close-button
              hide-header
              :label-today-button="$t('date.today')"
              :label-reset-button="$t('date.reset')"
              :label-close-button="$t('date.close')"
              today-button-variant="primary"
              reset-button-variant="danger" 
              close-button-variant="secondary"
              size="sm"
            ></b-form-datepicker>
        </span>
      </li>
      <li>
        <span class="d-flex align-items-center mr-1 date">
          <label class="mt-1 mr-1 align-self-baseline" for="endDate">{{ $t('staff.to') }}</label>
          <b-form-datepicker id="chartEndDate" v-model="endDate" class="d-flex" @input="dateChanged()"
              today-button
              reset-button
              close-button
              hide-header
              :label-today-button="$t('date.today')"
              :label-reset-button="$t('date.reset')"
              :label-close-button="$t('date.close')"
              today-button-variant="primary"
              reset-button-variant="danger" 
              close-button-variant="secondary"
              size="sm"
            ></b-form-datepicker>
        </span>
      </li>
      <li>
        <b-btn :id="`BTN_REFRESH_CHART`" class="ml-1" @click="daySelected" :pressed.sync="highlightRefresh">
          <font-awesome-icon :class="highlightRefresh ? 'active' : ''" :icon="['far', 'arrows-rotate']"/>
          <b-popover
            :target="`BTN_REFRESH_CHART`"
            placement="top"
            boundary="viewport"
            triggers="hover"
            :content="$t('button.refresh')">
          </b-popover>
        </b-btn>
      </li>
      <li>
        <span class="d-flex mr-1">
          <label class="mt-1 mr-1" for="timescale">{{ $t('staff.timescale') }}</label>
          <multiselect v-model="span" class="custom-dropdown-options dataview-bar enable-option-icon fit-label-fix fit-content-fix"
            :max-height="300"
            :options="spanOptions.map(i => i.value)"
            :custom-label="getSpanOptionLabel"
            :placeholder="''"
            :searchable="false" 
            :allow-empty="false"
            :showLabels="false"
            @input="updateSpan">
            <template slot="option" slot-scope="props">
              <font-awesome-icon class="selected-option-icon" v-if="span == props.option" :icon="['far', 'check']" />
              <span class="option__title">{{ getSpanOptionLabel(props.option) }}</span>
            </template>
          </multiselect>
        </span>
      </li>
      <!--<li>
        <span v-if="!epoch && startDate && endDate" class="d-flex ml-2 mr-2">
          <span class="mt-1 mr-1">{{ $t('dataview.chart.label') }}</span>
          <b-form-select id="fields" v-model="timeseries_label" :options="fieldOptions" @change="labelSelected()" class="mw-150 minw-170" size="sm"></b-form-select>
        </span>
      </li>-->
      <li>
        <span :id="`BTN_USE_EPOCH_chart`">
          <b-btn @click="toggleEpoch" :style="useEpoch ? 'color: #E77615' : ''"><font-awesome-icon :icon="['far','history']"/></b-btn>
          <b-popover
           :target="`BTN_USE_EPOCH_chart`"
             placement="top"
             boundary="viewport"
             triggers="hover"
           :content="useEpoch ? $t('dataview.timemachine_on') : $t('dataview.timemachine_off')">
          </b-popover>
        </span>
      </li>
    </PriorityNavigation>
    <div class="grid-toolbar border" v-if="allowManage && !isWidget">
      <span v-if="canView(entityName) && editPermission">
        <b-popover
          :target="`BTN_CHART_EDIT_chart`"
          placement="top"
          boundary="viewport"
          triggers="hover"
          :content="$t('button.edit')">
        </b-popover>
        <b-btn :id="`BTN_CHART_EDIT_chart`" @click="editChart()"><font-awesome-icon :icon="['far', 'pen-to-square']"/></b-btn>
      </span>
      <span v-if="canEdit(entityName) && editPermission">
        <b-popover
          :target="`BTN_CHART_DELETE_chart`"
          placement="top"
          boundary="viewport"
          triggers="hover"
          :content="$t('button.delete')">
        </b-popover>
        <b-btn :id="`BTN_CHART_DELETE_chart`" @click="removeChart()"><font-awesome-icon :icon="['far', 'trash-can']"/></b-btn>
      </span>
      <span readonly class="action-v-divider" v-if="editPermission">|</span>
      <span>
        <b-popover
          :target="`BTN_CHART_EXPORT_DOCUMENT_chart`"
          placement="top"
          boundary="viewport"
          triggers="hover"
          :content="$t('dataview.chart.export_chart')">
        </b-popover>
        <b-btn :id="`BTN_CHART_EXPORT_DOCUMENT_chart`" @click="chartExport(`${chart.name}`)"><font-awesome-icon :icon="['far', 'inbox-out']"/></b-btn>
      </span>
      <span @[timeModeMouseEnterEvent]="onTimeModeOver" @mouseleave="onTimeModeLeave">
        <b-dropdown :id="`BTN_TIME_MODE`" ref="timeMode" class="action-bar-dropdown" toggle-class="text-decoration-none" no-caret>
          <template #button-content>
            <font-awesome-icon :icon="['far', 'analytics']"/>
          </template>
          <b-dropdown-group :header="$t('dataview.date_settings')">
            <b-dropdown-item @click="onCurrent" href="#">
              <span class="action-item-label">{{ $t('dataview.current') }}</span><font-awesome-icon class="active-check" v-if="datesMode === 'current'" :icon="['far', 'check']"/>
            </b-dropdown-item>
            <b-dropdown-item @click="onActuals" href="#">
              <span class="action-item-label">{{ $t('dataview.actuals') }}</span><font-awesome-icon class="active-check" v-if="datesMode === 'actuals' && epoch && startDate && endDate" :icon="['far', 'check']"/>
            </b-dropdown-item>
            <b-dropdown-item v-if="canTimeseries" @click="onTimeSeries" href="#">
              <span class="action-item-label">{{ $t('dataview.timeseries') }}</span><font-awesome-icon class="active-check" v-if="datesMode === 'timeseries' && !epoch && startDate && endDate" :icon="['far', 'check']"/>
            </b-dropdown-item>
          </b-dropdown-group>
        </b-dropdown>
      </span>
    </div>
    <div :class="isWidget ? widgetContainer : 'chart-container'">
      <ag-charts-vue ref="agcharts" :class="isWidget ? 'widget-chart-style' : 'chart-style'" :style="style" v-if="options !== null" :options="options"></ag-charts-vue>
      <div class="loading" v-else>
        <b-spinner variant="primary" label="Loading..."></b-spinner>
      </div>
    </div>
  </div>
</template>

<script>
import { cloneDeep, debounce } from 'lodash';
import * as moment from 'moment-timezone';
moment.tz.setDefault('Etc/UTC');
const locale = navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language;
moment.locale(locale);
import Vue from 'vue';
import { AgChartsVue } from 'ag-charts-vue';
import * as agCharts from 'ag-charts-community';
import { queryService, compositeService } from '@/services';
import { 
  transformField, 
  buildFilter, 
  convertMsToDays, 
  getFieldType, 
  getDefaultValue, 
  calcEpochs,
  getNextWorkingDay,
  formatDate,
  getShortDate,
  formatFieldValue,
  EventBus,
  createBody } from '@/helpers';
import cColors from "@/_chartColors";
import PriorityNavigation from '@/components/PriorityNavigation/PriorityNavigation';
import { extractDurationConversionOpts } from '@/helpers/task-duration-process';
import { genericChartTooltipRendererGenerator } from '@/helpers/ag-chart-tooltip-renderer';
import Multiselect from 'vue-multiselect';

export default {
  name: 'Chart',
  components: {
    'ag-charts-vue': AgChartsVue,
    PriorityNavigation,
    Multiselect
  },
  props: {
    chart: { type: Object, default: null },
    dataview: { type: Object, default: null },
    schema: { type: Object, default: null },
    allowManage: { type: Boolean, default: true },
    editPermission: { type: Boolean, default: true },
    entityName: { type: String, default: null },
    height: { type: Number, default: 300 },
    width: { type: Number, default: 300 },
    isWidget: { type: Boolean, default: false }
  },
  data: function() {
    return {
      chartObj: null,
      options: null,
      totalRecords: 0,      
      fills: ['#FF9800',
              '#2196F3',
              '#4CAF50',
              '#FFC107',
              '#9C27B0',
              '#009688',
              '#F44336',
              '#139393'],
      strokes: ['#F57C00',
              '#1976D2',
              '#388E3C',
              '#FFA000',
              '#7B1FA2',
              '#00796B',
              '#D32F2F',
              '#AFB42B'],
      priorityNavLoaded: false,
      priorityNavTimeLoaded: false,
      span: 'Daily',
      startDate: null,
      endDate: null,
      epoch: null,
      epochs: [],
      spanOptions: [
        { text: "Day", value: "Daily" },
        { text: "Week", value: "Weekly" },
        { text: "Month", value: "Monthly" },
        { text: "Year", value: "Yearly" }
      ],
      dates: "this-week",
      highlightRefresh: false,
      datesStr: 'this-week', //a workaround for multiselect compeonent which can't accept null value.
      datesMode: "current",
      dateOptions: [
        // { text: 'Custom',  value: null},
        { text: 'Custom',  value: 'null'},
        // - means the project's start date and end date attributes (if exist).  
        // If both don't exist, then error msg "Project start date or end date 
        // not defined." and From/To dates don't change.
        //{ text: "Project start-to-end", value: "project-start-to-end" }, 
        // - means the earliest task start date to the latest tasks finish date 
        // in the project (hopefully from Chris' macro?)
        //{ text: "Project schedule", value: "project-schedule" },  
        // - means this calendar week Mon-Sun (01/06/2020 - 07/06/2020)
        { text: "This week", value: "this-week" },
        // - means this calendar week Mon-Sun  to today's date (01/06/2020 - 03/06/2020)  
        { text: "This week-to-date", value: "this-week-to-date" },  
        // - means this calendar month (01/06/2020 - 30/06/2020)
        { text: "This month", value: "this-month" },  
        // - means this calendar month to today's date (01/06/2020 - 03/06/2020)
        { text: "This month-to-date", value: "this-month-to-date" },
        // - means this calendar quarter (01/04/2020 - 30/06/2020)  
        { text: "This quarter", value: "this-quarter" },  
        // - means this calendar quarter to today's date (01/04/2020 - 03/06/2020)
        { text: "This quarter-to-date", value: "this-quarter-to-date" },
        // - means this calendar year (01/01/2020 -> 31/12/2020)  
        { text: "This year", value: "this-year" },  
        // - means this calendar year to today's date (01/01/2020 -> 03/06/2020)
        { text: "This year-to-date", value: "this-year-to-date" },
        // - means last Mon-Sun block (week) (25/05/2020 - 31/05/2020)  
        { text: "Last week", value: "last-week" },  
        // - means last Mon-Sun block (week) to today's date (25/05/2020 - 03/06/2020)
        { text: "Last week-to-date", value: "last-week-to-date" },
        // - means last calendar month (01/05/2020 - 31/05/2020)  
        { text: "Last month", value: "last-month" },  
        // - means last calendar month to today's date (01/05/2020 - 03/06/2020)
        { text: "Last month-to-date", value: "last-month-to-date" },
        // - means last calendar quarter (01/01/2020 - 31/03/2020)  
        { text: "Last quarter", value: "last-quarter" },  
        // - means last calendar quarter (01/01/2020 - 31/03/2020)
        { text: "Last quarter-to-date", value: "last-quarter-to-date" },
        // - means last calendar year (01/01/2019 -> 31/12/2019)  
        { text: "Last year", value: "last-year" },  
        // - means next Mon-Sun blocks (week) from today (08/06/2020 - 14/06/2020)
        { text: "Next week", value: "next-week" },  
        // - means next 4 Mon-Sun blocks (weeks) from today (including this week if mid-week) (01/06/2020 - 28/06/2020)
        { text: "Next 4 weeks", value: "next-4-weeks" },
        // - means next calendar month (01/07/2020 - 31/07/2020)  
        { text: "Next month", value: "next-month" },  
        // - means next calendar quarter (01/10/2020 - 31/12/2020)
        { text: "Next quarter", value: "next-quarter" },
        // - means next calendar year (01/01/2021 -> 31/12/2021)  
        { text: "Next year", value: "next-year" } 
      ],
      fieldOptions: [],
      numberFieldOptions: [],
      timeseries_field: null,
      timeseries_label: null,
      timeseries_key: null,
      epochOptions: [],
      useEpoch: true,
      mdColors: [
        '#F44336',
        '#FFEBEE',
        '#FFCDD2',
        '#EF9A9A',
        '#E57373',
        '#EF5350',
        '#F44336',
        '#E53935',
        '#D32F2F',
        '#C62828',
        '#B71C1C',
        '#FF8A80',
        '#FF5252',
        '#FF1744',
        '#D50000',
        '#E91E63',
        '#FCE4EC',
        '#F8BBD0',
        '#F48FB1',
        '#F06292',
        '#EC407A',
        '#E91E63',
        '#D81B60',
        '#C2185B',
        '#AD1457',
        '#880E4F',
        '#FF80AB',
        '#FF4081',
        '#F50057',
        '#C51162',
        '#9C27B0',
        '#F3E5F5',
        '#E1BEE7',
        '#CE93D8',
        '#BA68C8',
        '#AB47BC',
        '#9C27B0',
        '#8E24AA',
        '#7B1FA2',
        '#6A1B9A',
        '#4A148C',
        '#EA80FC',
        '#E040FB',
        '#D500F9',
        '#AA00FF',
        '#673AB7',
        '#EDE7F6',
        '#D1C4E9',
        '#B39DDB',
        '#9575CD',
        '#7E57C2',
        '#673AB7',
        '#5E35B1',
        '#512DA8',
        '#4527A0',
        '#311B92',
        '#B388FF',
        '#7C4DFF',
        '#651FFF',
        '#6200EA',
        '#3F51B5',
        '#E8EAF6',
        '#C5CAE9',
        '#9FA8DA',
        '#7986CB',
        '#5C6BC0',
        '#3F51B5',
        '#3949AB',
        '#303F9F',
        '#283593',
        '#1A237E',
        '#8C9EFF',
        '#536DFE',
        '#3D5AFE',
        '#304FFE',
        '#2196F3',
        '#E3F2FD',
        '#BBDEFB',
        '#90CAF9',
        '#64B5F6',
        '#42A5F5',
        '#2196F3',
        '#1E88E5',
        '#1976D2',
        '#1565C0',
        '#0D47A1',
        '#82B1FF',
        '#448AFF',
        '#2979FF',
        '#2962FF',
        '#03A9F4',
        '#E1F5FE',
        '#B3E5FC',
        '#81D4FA',
        '#4FC3F7',
        '#29B6F6',
        '#03A9F4',
        '#039BE5',
        '#0288D1',
        '#0277BD',
        '#01579B',
        '#80D8FF',
        '#40C4FF',
        '#00B0FF',
        '#0091EA',
        '#00BCD4',
        '#E0F7FA',
        '#B2EBF2',
        '#80DEEA',
        '#4DD0E1',
        '#26C6DA',
        '#00BCD4',
        '#00ACC1',
        '#0097A7',
        '#00838F',
        '#006064',
        '#84FFFF',
        '#18FFFF',
        '#00E5FF',
        '#00B8D4',
        '#009688',
        '#E0F2F1',
        '#B2DFDB',
        '#80CBC4',
        '#4DB6AC',
        '#26A69A',
        '#009688',
        '#00897B',
        '#00796B',
        '#00695C',
        '#004D40',
        '#A7FFEB',
        '#64FFDA',
        '#1DE9B6',
        '#00BFA5',
        '#4CAF50',
        '#E8F5E9',
        '#C8E6C9',
        '#A5D6A7',
        '#81C784',
        '#66BB6A',
        '#4CAF50',
        '#43A047',
        '#388E3C',
        '#2E7D32',
        '#1B5E20',
        '#B9F6CA',
        '#69F0AE',
        '#00E676',
        '#00C853',
        '#8BC34A',
        '#F1F8E9',
        '#DCEDC8',
        '#C5E1A5',
        '#AED581',
        '#9CCC65',
        '#8BC34A',
        '#7CB342',
        '#689F38',
        '#558B2F',
        '#33691E',
        '#CCFF90',
        '#B2FF59',
        '#76FF03',
        '#64DD17',
        '#CDDC39',
        '#F9FBE7',
        '#F0F4C3',
        '#E6EE9C',
        '#DCE775',
        '#D4E157',
        '#CDDC39',
        '#C0CA33',
        '#AFB42B',
        '#9E9D24',
        '#827717',
        '#F4FF81',
        '#EEFF41',
        '#C6FF00',
        '#AEEA00',
        '#FFEB3B',
        '#FFFDE7',
        '#FFF9C4',
        '#FFF59D',
        '#FFF176',
        '#FFEE58',
        '#FFEB3B',
        '#FDD835',
        '#FBC02D',
        '#F9A825',
        '#F57F17',
        '#FFFF8D',
        '#FFFF00',
        '#FFEA00',
        '#FFD600',
        '#FFC107',
        '#FFF8E1',
        '#FFECB3',
        '#FFE082',
        '#FFD54F',
        '#FFCA28',
        '#FFC107',
        '#FFB300',
        '#FFA000',
        '#FF8F00',
        '#FF6F00',
        '#FFE57F',
        '#FFD740',
        '#FFC400',
        '#FFAB00',
        '#FF9800',
        '#FFF3E0',
        '#FFE0B2',
        '#FFCC80',
        '#FFB74D',
        '#FFA726',
        '#FF9800',
        '#FB8C00',
        '#F57C00',
        '#EF6C00',
        '#E65100',
        '#FFD180',
        '#FFAB40',
        '#FF9100',
        '#FF6D00',
        '#FF5722',
        '#FBE9E7',
        '#FFCCBC',
        '#FFAB91',
        '#FF8A65',
        '#FF7043',
        '#FF5722',
        '#F4511E',
        '#E64A19',
        '#D84315',
        '#BF360C',
        '#FF9E80',
        '#FF6E40',
        '#FF3D00',
        '#DD2C00',
        '#795548',
        '#EFEBE9',
        '#D7CCC8',
        '#BCAAA4',
        '#A1887F',
        '#8D6E63',
        '#795548',
        '#6D4C41',
        '#5D4037',
        '#4E342E',
        '#3E2723',
        '#9E9E9E',
        '#FAFAFA',
        '#F5F5F5',
        '#EEEEEE',
        '#E0E0E0',
        '#BDBDBD',
        '#9E9E9E',
        '#757575',
        '#616161',
        '#424242',
        '#212121',
        '#607D8B',
        '#ECEFF1',
        '#CFD8DC',
        '#B0BEC5',
        '#90A4AE',
        '#78909C',
        '#607D8B',
        '#546E7A',
        '#455A64',
        '#37474F',
        '#263238',
        '#000000',
        '#FFFFFF',
      ],
      durationConversionOpts: {},
      chartResizedFlip: true //used to force the computed property to update/recalculate.
    };
  },
  watch: {
    chart(value) {
      this.options = null;
      this.chartObj = value;
      this.loadChartData(value);
    }
  },
  computed: {
    spanPrefix() {
      const prefix = this.spanOptions.filter(s => s.value === this.span);
      if (prefix.length > 0) {
        return prefix[0].text;
      }
      return '';
    },
    style() {
      this.chartResizedFlip; //used to force the computed property to update/recalculate.
      
      let height = 0;
      let width = 0;
      if (this.totalRecords > 80 || (this.totalRecords > 60 && (this.chart.series[0].type === 'pie' || this.chart.series[0].type === 'doughnut'))) {
        if (this.chart.series[0].type === 'bar') {
          height = this.totalRecords * 20;
        }
        else {
          width = this.totalRecords * 15;
        }
      }

      if (!this.isWidget && (this.chart.fit || this.datesMode === 'timeseries')) {
        //Use gridToolbar as reference for the width value
        const gridToolbar = document.querySelector('.grid-toolbar');
        let gridToolbarWidth = null;
        if (gridToolbar != null) {
          gridToolbarWidth = gridToolbar.getBoundingClientRect().width - 18;
        }

        //calculate height
        let heightOffset = 137; //137 is the perfect point for most chart not to have vertical scroll.
        if (Array.isArray(this.options?.series) && this.options.series.length > 0 
              && this.options.series[0] != null 
              && (this.options.series[0].type == 'bubble' || this.options.series[0].type == 'scatter')) {
          heightOffset = 180; //180 is the perfect point for bubble and scatter charts not to have vertical scroll.
        }
        const appHeader = document.querySelector('.app-header.navbar');
        if (appHeader != null) {
          heightOffset += appHeader.getBoundingClientRect().height;
        }
        const breadcrumb = document.querySelector('.breadcrumb');
        if (breadcrumb != null) {
          heightOffset += breadcrumb.parentElement.getBoundingClientRect().height;
        }
        
        return `height: calc(100vh - ${heightOffset}px) !important;${gridToolbarWidth != null? 'width: ' + gridToolbarWidth + 'px' : ''}`;
        // return '';
      }
      else if (!this.isWidget) {
        let styleString = '';
        if (height !== 0) {
          return `height: ${height}px !important;`;
        } else {
          styleString += 'height: 613px !important;min-height: 613px;';
        }
        if (width !== 0) {
          return `width: ${width}px !important;${styleString}`;
        }
        return styleString;
      }
      else {
        return `height: ${this.height}px !important; width: ${this.width - 40}px`;
      }
    },
    widgetContainer() {
      return `min-width: ${this.width}px; overflow: visible;`
    },
    disableEpochLeft() {
      return this.epoch === null || this.epochs.length === 0 || 0 === this.epochs.findIndex(e => e === this.epoch);
    },
    disableEpochRight() {
      return this.epoch === null || this.epochs.length === 0 || this.epochs.length - 1 === this.epochs.findIndex(e => e === this.epoch);
    },
    canTimeseries() {
      return this.chartObj !== null &&
             this.chartObj.series.length > 0 && 
             this.chartObj.series[0].type !== 'pie' &&
             this.chartObj.series[0].type !== 'doughnut' &&
             this.chartObj.series[0].type !== 'histogram';
    },
    timeModeMouseEnterEvent() {
      return this.isTouchDevice()? null : 'mouseenter';
    },
    nominate() {
      if (this.chart &&
          typeof this.chart.nominate === 'string') {
        return this.chart.nominate;   
      }
      
      if (this.chart &&
          this.chart.nominate) {
          if (this.hasSubEntities(buildFilter(this.chart.entity, this.chart.filter))) {
            return 'group';
          }
          return 'nominate';   
      }
      return 'left-join';
    }
  },
  beforeMount() {
    
  },
  created() {
    EventBus.$on('theme-change', () => {
      this.loadChartData(this.chartObj);
    });

    this.chartObj = this.chart;
    this.loadChartData(this.chartObj);
  },
  beforeDestroy() {
    EventBus.$off('theme-change');
    window.removeEventListener('resize', this.chartResizeHandler);
    this.options = null;
  },
  mounted() {
    window.addEventListener('resize', this.chartResizeHandler);
  },
  methods: {
    chartResizeHandler: debounce(function(/** e */) {
      this.chartResizedFlip = !this.chartResizedFlip;
    }, 300),
    hasSubEntities(filter) {
      if (Array.isArray(filter)) {
        for (const f of filter) {
          if (Array.isArray(f)) {
            const res = this.hasSubEntities(f);
            if (res) {
              return true;
            }
          }
          else if (typeof f === 'string') {
            const m =  f.match(/\./g);
            if (m !== null && m.length > 1) {
              return true;
            }
          }
        }
      }
      else if (typeof filter === 'string') {
        const m = filter.match(/\./g);
        if (m !== null && m.length > 1) {
          return true;
        }
      }
      return false;
    },
    applyPermissions(data) {
      if (data.fields) {
        data = cloneDeep(data);
        for (var i = data.fields.length - 1; i >= 0; i--) {
          const entry = typeof data.fields[i] === 'object' ? data.fields[i].field.split('.') : data.fields[i].split('.');
          const entity = entry[entry.length - 2];
          const permList = this.$store.state.authentication.user.permissionList.filter(f => f.name === `${entity}__VIEW`);
          const perms = permList.length > 0 ? 
                        permList[0] : 
                        [];
          const denyRules = perms && perms.permissionLink && perms.permissionLink.denyRules ?
                            perms.permissionLink.denyRules : [];
          if (denyRules.includes(entry[entry.length - 1])) {
            data.fields.splice(i, 1);
          }
        }
      }
      return data;
    },
    async loadChartData(data) {
      this.span = data.span ? data.span : 'Daily';
      this.startDate = data.startDate;
      this.endDate = data.endDate;
      this.dates = typeof data.dates !== 'undefined' ? data.dates : 'this-week';
      this.datesStr = this.dates == null? 'null' : this.dates;
      this.datesMode = typeof data.datesMode !== 'undefined' ? data.datesMode : 'current';
      this.epoch = this.datesMode === 'current' ? null : data.epoch;
      this.useEpoch = typeof data.useEpoch !== 'undefined' ? data.useEpoch : false;
      this.datesChanged();
      let chart = this.applyPermissions(data);
      const self = this;
      const fields = [];
      var ksort = data.sortfield;
      const order = data.sortdirection;
      const ksortAgFunc = typeof data.sortAgFunc !== 'undefined' && data.sortAgFunc.applyFunction ? data.sortAgFunc.agFunction : null;
      if (ksort !== null &&
          typeof ksort !== 'undefined' && 
          ksort.includes('.=')) {
        ksort = transformField(ksort, 'Macro', 'sort');
      }
      
      // transform the macros
      for (var i = 0; i < chart.fields.length; i++) {
        if (typeof chart.fields[i] === 'string' && chart.fields[i].includes('.=')) {
          fields.push(transformField(chart.fields[i], 'Macro'));
        }
        else if (typeof chart.fields[i] === 'object') {
          const agField = chart.fields[i].field.includes('.=') ? transformField(chart.fields[i].field, 'Macro')[0] : chart.fields[i].field;
          const agFunction = chart.fields[i].agFunction;
          if (agFunction === 'year' ||
            agFunction === 'month' ||
            agFunction === 'day' ||
            agFunction === 'cos' ||
            agFunction === 'sin' ||
            agFunction === 'tan' ||
            agFunction === 'length') {
            fields.push([agField, getDefaultValue(agField), agFunction]);
          }
          else {
            fields.push([agField, 0, getDefaultValue(agField), agFunction]);
          }
        }
        else {
          fields.push(chart.fields[i]);
        }
      }
          
      // make sure we have TASK.PROJECT.name in the select when using macros
      if ((chart.fields.includes('TASK.PROJECT.=fullPath(A)') ||
           chart.fields.includes('TASK.PROJECT.=timeStamp(A)')) &&
          !(chart.fields.includes('TASK.PROJECT.name'))) {
        fields.push('TASK.PROJECT.name');
      }

      this.timeseries_key = `${chart.entity}.uuId`;
      if (!fields.includes(this.timeseries_key)) {
        fields.push(this.timeseries_key);
        chart.fields.push(this.timeseries_key);
      }
      this.timeseries_label = chart.axes[0].keys[0];//chart.timeseries_label ? chart.timeseries_label : chart.fields[0];
      this.populateFieldOptions(chart.fields, chart.timeseries_field ? chart.timeseries_field : chart.axes[1].keys[0]);
      this.numberFields(chart.fields, chart.timeseries_field ? chart.timeseries_field : null);
      
      const epochResult = calcEpochs(self.span, self.startDate, self.endDate);
      self.epochs = epochResult.epochs;
      self.epochOptions = epochResult.epochOptions;
    
      if (self.datesMode !== 'current' &&
          !self.epoch && 
          self.epochs.length !== 0) {
        const allData = [];
        const cmdList = [];
        chart.series[0].yKeys = [];
        chart.series[0].yNames = [];
        let index = 1;
        for (const epoch of self.epochs) {
          cmdList.push({
             "note":`${index}-query data for time series`
            ,"invoke" : self.useEpoch ? `/api/query/match?epoch=${epoch}` : "/api/query/match"
            ,"body"   :  createBody({ 
              start: 0, 
              limit: -1, 
              ksort: ksort,
              ksortAgFunc: ksortAgFunc,
              order: order,
              epoch: self.useEpoch ? epoch : null
          }, fields, self.replaceNow(buildFilter(chart.entity, chart.filter), epoch), 
           { type: self.nominate, field: chart.entity }, 
           chart.dedup)
          });
          index++;
        }
           
        const responseData = await compositeService.exec(cmdList, true)
        .then(response => {
          return response.data.feedbackList;
        })
        .catch(() => {
          return null;
        });
            
        if (responseData) {
          const allkeys = {};
          const map = {};
          for (let idx = 0; idx < responseData.length; idx++) {
            const result = self.processData(chart, responseData[idx].fetch, self)
            const preparedData = result.preparedData;
            for (const dataPoint of preparedData) {
              const epoch = self.epochs[idx];
  
              if (self.timeseries_label) {
                // Get the value from the data for use as a label
                const dataLabel = typeof dataPoint[self.timeseries_label] === 'string'
                                ? dataPoint[self.timeseries_label] 
                                : Array.isArray(dataPoint[self.timeseries_label]) 
                                ? dataPoint[self.timeseries_label][0] 
                                : typeof dataPoint[self.timeseries_label] === 'number' 
                                ? `${dataPoint[self.timeseries_label]}` 
                                : dataPoint[self.timeseries_label];
                const dataKey = typeof dataPoint[self.timeseries_key] === 'string'
                                ? dataPoint[self.timeseries_key] 
                                : Array.isArray(dataPoint[self.timeseries_key]) 
                                ? dataPoint[self.timeseries_key][0] 
                                : typeof dataPoint[self.timeseries_key] === 'number' 
                                ? `${dataPoint[self.timeseries_key]}` 
                                : dataPoint[self.timeseries_key];
                if (chart.series[0].type === 'column' || chart.series[0].type === 'bar') {
                  dataPoint.epoch = self.formatEpoch(epoch);
                  if (chart.series[0].yKeys && !chart.series[0].yKeys.includes(dataKey)) {
                    chart.series[0].yKeys.push(dataKey);
                    chart.series[0].yNames.push(dataLabel);
                    chart.axes[1].keys.push(dataKey);
                  }
                }
                else {
                  chart = self.addSeries(chart, dataKey, dataLabel);
                  dataPoint.epoch = moment(epoch).format(self.getAxisFormat());
                }
                
                dataPoint[dataKey] = dataPoint[self.timeseries_field];
              }
                
              if (epoch in map) {
                // add the values to the existing data point
                const point = map[epoch];
                for (const key of Object.keys(dataPoint)) {
                  // keep a record of all keys for normalization
                  allkeys[key] = null; //use null value instead of 0 to represent missing data
                  if (typeof point[key] === 'number') {
                    point[key] += dataPoint[key];
                  }
                  else {
                    point[key] = dataPoint[key];
                  }
                }
              }
              else {
                // keep a record of all keys for normalization
                for (const key of Object.keys(dataPoint)) {
                  allkeys[key] =  null //use null value instead of 0 to represent missing data
                }
                map[epoch] = dataPoint;
                allData.push(dataPoint);
              }
            }
          }
          
          // normalize and fill in blanks
          for (const ddata of allData) {
            for (const dkey of Object.keys(allkeys)) {
              if (!(dkey in ddata)) {
                ddata[dkey] = null //use null value instead of 0 to represent missing data
              }
            }
          }
        } 
        // remove the initial series because we have replaced it
        if (chart.series.length > 1) {
          chart.series.shift();
        }
        
        self.processTimeSeriesData(chart, allData, self);
      }
      else {
        const requestData = await queryService.query({ 
            start: 0, 
            limit: -1, 
            ksort: ksort,
            ksortAgFunc: ksortAgFunc,
            order: order,
            epoch: self.epoch && self.useEpoch ? self.epoch : null
        }, self.processFieldsForGroup(fields), 
           self.replaceNow(buildFilter(chart.entity, chart.filter), self.epoch), 
           { type: self.nominate === 'group-by' ? 'group' : self.nominate, field: self.nominate === 'group-by' ? chart.groupfield : chart.entity }, 
           chart.dedup)
        .then(response => {
          const data = response.data[response.data.jobCase];
          if (typeof data === 'undefined') {
            return null;
          }
          return data;
        })
        .catch(function(error) {
          console.error(error); // eslint-disable-line no-console
          self.$emit('error', error);
          self.options = {}; // hide the spinner
          return null;
        });
        
        if (requestData) {
          this.processActualsData(chart, requestData, self);
        }
      }
    },
    processData(chart, data /**, self */) {
        const keys = chart.fields;
        var labelKeys = 0;
        const xKeys = {};
        const preparedData = data.map(i => {
          const result = {}
          for (let j = 0, len = i.length; j < len; j++) {
            const fieldType = getFieldType(keys[j], this.schema);
            if (fieldType === 'Date') {
              result[keys[j]] = moment(i[j]).format('YYYY-MM-DD hh:mm A');
            } else if (fieldType === 'Duration' || fieldType === 'MinuteDuration' || (typeof keys[j] === 'object' && keys[j].field.endsWith('.duration'))) {
              if (typeof keys[j] === 'object') {
                result[`${keys[j].agFunction.toUpperCase()}(${keys[j].field})`] = convertMsToDays(i[j], this.durationConversionOpts);
              } else {
                result[keys[j]] = convertMsToDays(i[j], this.durationConversionOpts);
              }
            } else if (fieldType === 'Progress' || (typeof keys[j] === 'string' && keys[j].endsWith('.rebate'))) {
              result[keys[j]] = Math.round((Array.isArray(i[j]) ? i[j].reduce((a, b) => a + b, 0) : i[j]) * 100);
            } else if ((fieldType === 'Cost' || fieldType === 'Macro') && typeof keys[j] === 'string') {
              if (keys[j].includes('Duration')) {
                // remove the 'D'
                result[keys[j]] = parseFloat(i[j].slice(0, -1));
              } else if (keys[j].includes('Progress')) {
                result[keys[j]] = Math.round(i[j] * 100);
              } else if (keys[j].includes('Cost')) {
                result[keys[j]] = i[j] !== -1 ? i[j] : 0;// Math.round(i[j] / 1000);
              } else {
                result[keys[j]] = i[j];
              }
            } else if (typeof keys[j] === 'object') {
              result[`${keys[j].agFunction.toUpperCase()}(${keys[j].field})`] = i[j];
            } else  if (typeof i[j] === 'string' && i[j].indexOf('\n') !== -1) {
              result[keys[j]] = i[j].replace(/\n/g, ' / ');
            } else {
              result[keys[j]] = i[j];
            }
            
            if (chart.series.length > 0) {
              // count the xKeys
              if ((keys[j] === chart.series[0].xKey || 
                  (Array.isArray(chart.series[0].xKey) && keys[j] === chart.series[0].xKey[0] && chart.series[0].xKey.length === 1)) && 
                  chart.series[0].type !== 'pie' &&
                  chart.series[0].type !== 'doughnut') {
                if (chart.axes[0].type === 'time' && i[j] !== 0) {
                  result[keys[j]] = new Date(i[j]); // use Date object for type time
                }
                  
                xKeys[i[j]] = i[j];
              }
              else if (keys[j] === chart.series[0].labelKey) {
                labelKeys++;
              }
              
              //Handle deprecated data format after ag-chart-community v6.0.0 upgrade.
              //1) Handle new data format for series when there are multiple values in yKeys or xKeys.
              //2) and, remove unused property to avoid console warning
              // const newSeries = []; //Duplicate series for each value in yKeys.
              for (const s of chart.series) {
                const propKeys = Object.keys(s);
                for (const k of propKeys) {
                  if (s[k] == null || (Array.isArray(s[k]) && (s[k].length == 0 || s[k].filter(i => i == null).length == s[k].length))) {
                    if (!this.canTimeseries || s.type === 'line' || (k !== 'yKeys' && k !== 'yNames')) { // yKeys and yNames are needed for time series
                      delete s[k];
                    }
                  }
                }

                if (s.type == 'line') {
                  delete s.angleKey;
                  delete s.labelKey;
                  delete s.yNames;
                  delete s.yKeys;
                }
                else if (s.type === 'column') {
                  delete s.yName;
                  delete s.yKey;
                  delete s.labelKey;
                  delete s.angleKey;
                }
                else if (s.type === 'bubble' ||
                         s.type === 'scatter') {
                  delete s.yNames;
                  delete s.yKeys;
                  delete s.angleKey;        
                }
              }
              // if (newSeries.length > 0) {
              //   chart.series.push(...newSeries);
              // }
            }
          }
          
          const formatValue = (key, value) => { return formatFieldValue(key, value, this.schema, { durationFunc: (v) => `${v}D`, percentageFunc: (v) => `${v}%` }, this.durationConversionOpts) }
          // composite keys
          if (chart.series[0].xKey != null && Array.isArray(chart.series[0].xKey) && chart.series[0].type !== 'histogram') {
            var compositeVal = typeof chart.format !== 'undefined' ? chart.format : chart.series[0].xKey.join(' ');
            for (var idx = 0; idx < chart.series[0].xKey.length; idx++) {
              const _k = chart.series[0].xKey[idx];
              const _v = formatValue(_k, result[chart.series[0].xKey[idx]]);
              // we remove the parameter from macros for display "TASK.=fullPath(A)" becomes "TASK.=fullPath()"
              if (_k.includes(".=fullPath(A)") && compositeVal.includes(".=fullPath()")) {
                compositeVal = compositeVal.replace("fullPath()", "fullPath(A)");
              }
              compositeVal = compositeVal.replace(_k, _v);
            }
            result[chart.series[0].xKey.join(' ')] = compositeVal;
            xKeys[compositeVal] = compositeVal;
          }
  
        return result;
      });
      return { preparedData: preparedData, labelKeys: labelKeys };
    },
    addFormatting(options, chart) {
        const formatValue = (key, value) => { return formatFieldValue(key, value, this.schema, { durationFunc: (v) => `${v}D`, percentageFunc: (v) => `${v}%` }, this.durationConversionOpts) }
      
        if (typeof chart.legend !== 'undefined') {
          options.legend = chart.legend;
          if (options.legend.item == null) {
            options.legend.item = {}
          }
          if (options.legend.item.label == null) {
            options.legend.item.label = {}
          }

          options.legend.item.label.fontFamily = 'Roboto';
          options.legend.item.label.color = cColors.getThemeColor('text-medium');
          
          if (options.legend.item.marker == null) {
            options.legend.item.marker = {}
          }
          options.legend.item.marker.shape = 'circle';
          options.legend.item.marker.strokeWidth = 0;
        }
        
        if (chart.series.length > 0 && chart.series[0].type === 'doughnut') {
          options.series[0].innerRadiusOffset = -150;
          options.series[0].type = 'pie';
        }
        
        if (options.series[0].type === 'pie') {
          options.series[0].strokes = ['#ffffff'];
          options.series[0].strokeWidth = 2; 
          
          // if no legend add some padding to the bottom of the chart to
          // help the labels appear
          if (!options.legend.enabled) {
            options.padding = { bottom: 50 };    
          }
        }
        
        for (var series of chart.series) {
          if (Array.isArray(series.xKey)) {
            if (series.xKey.length > 1) {
              series.xKey = chart.series[0].xKey.join(' ');
              chart.axes[0].keys[0] = series.xKey;
              chart.axes[0].type = 'category';
            }
            else {
              series.xKey = series.xKey[0];
            }
          }


          
          if (series.sizeKey != null && Array.isArray(series.sizeKey)) {
            series.sizeKey = series.sizeKey[0];
          }
        }
        
        if (chart.series.length > 0 && (chart.series[0].type === 'bubble' || chart.series[0].type === 'scatter')) {
          // options.series[0].type = 'scatter';
          options.series[0].marker = {
            maxSize: 100,
            size: 5,
            fillOpacity: 0.5,
            fill: this.fills[0],
            stroke: this.strokes[0]
          };
          //Handle deprecated data format after ag-chart-community v6.0.0 upgrade.
          if (options.series[0].fills != null) {
            if (options.series[0].fills.length > 0) {
              options.series[0].marker.fill = options.series[0].fills[0];
            }
            delete options.series[0].fills;
          }
          if (options.series[0].strokes != null) {
            if (options.series[0].strokes.length > 0) {
              options.series[0].marker.stroke = options.series[0].strokes[0];
            }
            delete options.series[0].strokes;
          }
        }
        
        if (chart.series.length > 0 && 
            chart.series[0].type !== 'pie' &&
            chart.series[0].type !== 'doughnut') {
          options.axes = chart.axes;
        }

        //Handle deprecated data format after ag-chart-community v6.0.0 upgrade.
        if (chart.series.length > 0 && chart.series[0].type == 'line') {
          if (chart.series[0].fills != null) {
            chart.series[0].stroke = chart.series[0].fills[0];
          } else {
            chart.series[0].stroke = this.fills[0]; //As agreed, use the orange-ish color.
          }
          
          if (!this.canTimeseries || chart.series[0].type === 'line') {
            delete chart.series[0].fills;
            delete chart.series[0].strokes;
          }
        }

        //Handle deprecated data format after ag-chart-community v6.0.0 upgrade.
        if (options.series.length > 0) {
          const fillsMaxIndex = this.fills.length - 1;
          const strokesMaxIndex = this.strokes.length -1;
          for (const [i, s] of options.series.entries()) {
            if (s.type == 'scatter' || s.type == 'pie' || s.type == 'line' || s.type === 'area') {
              continue;
            }

            if (s.fills != null) {
              if (options.series.length === 1) {
                s.fill = s.fills[i % s.fills.length];
              }
              else {
                s.fill = this.fills[i % this.fills.length];
                s.fills = [s.fill];
              }
            } else {
              s.fill = this.fills[i % fillsMaxIndex]
            }
            
            
            if (!this.canTimeseries) {
              delete s.fills;
            }
            
            if (s.strokes != null) {
              s.stroke = s.strokes[i % s.strokes.length];
            } else {
              s.stroke = this.strokes[i % strokesMaxIndex]
            }
            
            
            if (!this.canTimeseries) {
              delete s.strokes;
            }
          }
        }

        
        if (options.axes) {
          for (var axis of options.axes) {
            if (!axis.label) {
              axis.label = {};
            }
            if (!axis.title) {
              axis.title = {};
            }
            // Add theme colors
            axis.title.color = cColors.getThemeColor('text-medium');
            axis.label.color = cColors.getThemeColor('text-medium');
            // axis.gridStyle = [{ stroke: cColors.getThemeColor('axis-stroke'), lineDash: [0, 0] }];
            axis.gridLine = { style: [{ stroke: cColors.getThemeColor('axis-stroke'), lineDash: [0, 0] }] };

            // Add formatter for axis
            const key = axis.keys && axis.keys.length > 0? axis.keys[0] : null;
            axis.label.formatter = (params) => {
              if (params.value instanceof Date) {
                return moment(params.value).format(this.getAxisFormat());
              }
              else if (key != null) {
                let retVal = '' + formatValue(key, params.value); //Return value have to be string. Otherwise, Aggrid will not print the label.
                if (retVal.length > 30) {
                  retVal = `...${retVal.substr(retVal.length - 30)}`;
                }
                return retVal;
              }
              return params.value;
            }
            
          }
        }
        if (options.series) {
          // for (var s of options.series) {
          for (let i = 0, len = options.series.length; i < len; i++) {
            const s = options.series[i];
            if (s.type !== 'pie' && !s.label) {
              s.label = {}; 
            }
            if (s.type == 'bar' || s.type == 'column' || s.type == 'histogram') {
              // We don't want labels on these type, they don't look good.
              s.label.enabled = false;
            } else if (s.label) {
              s.label.color = cColors.getThemeColor('text-medium');
            } 

            // Add formatter for label
            if (s.type == 'area' || s.type == 'line') {
              if (s.label == null) {
               s.label = {}
              }
              s.label.enabled = true;
              const yKey = s.yKeys && s.yKeys.length > 0? s.yKeys[0] : null;
              s.label.formatter = (params) => {
                if (yKey != null) {
                  return formatValue(yKey, params.value);
                }
                return params.value;
              }
            }

            //Special treatment for pie and doughnut on label, calloutLine and padding bottom
            if (s.type == 'pie' || s.type == 'doughnut') {
              if (!s.calloutLabel) {
                s.calloutLabel = {};
                s.calloutLabel.color = cColors.getThemeColor('text-medium');
              }
              
              if (s.calloutLabel != null) {
                s.calloutLabel.offset = 10;
              }
              if (s.calloutLine == null) {
                s.calloutLine = {}
              }
              s.calloutLine.length = 30;

              if (options.legend != null && options.legend.position != null)  {
                if (options.padding == null) {
                  options.padding = {}
                }
                options.padding.bottom = 60;
                options.padding.top = 60;
              }
            }
            else if (s.type === 'scatter') {
              if (options.padding == null) {
                options.padding = {}
              }
              options.padding.right = 60;
            }
            
            if (options.legend !== null && options.legend.position === null)  {
              delete options.legend.position;
            }
          }
        }
        return options;
    },
    processActualsData(chart, data, self) {
      const result = self.processData(chart, data, self)
      const preparedData = result.preparedData;
      const labelKeys = result.labelKeys;
      self.totalRecords = chart.series.length > 0 && (chart.series[0].type === 'pie' || chart.series[0].type === 'doughnut') && chart.legend.enabled ? labelKeys : data.length < 1000 ? data.length : 300;
      
      let options = { 
        data: preparedData,
        background: {
          fill: cColors.getThemeColor('surface-bg')
        },
        title: {
          text: chart.name,
          fontSize: 18,
          fontFamily: 'Roboto',
          color: cColors.getThemeColor('text-dark')
        },
        subtitle: { 
          text: chart.description,
          fontFamily: 'Roboto',
          color: cColors.getThemeColor('text-light')
        },
        series: chart.series
      };
      
      if (typeof chart.legend !== 'undefined') {
        options.legend = chart.legend;
        //options.legend.fontFamily = 'Roboto';
        //options.legend.markerShape = 'circle';
        //options.legend.strokeWidth = 0;
        options.legend.item = { label: { color: cColors.getThemeColor('text-medium')}}
      }
      
      if (chart.series.length > 0 && chart.series[0].type === 'doughnut') {
        options.series[0].innerRadiusOffset = -150;
        options.series[0].type = 'pie';
      }
      
      if (options.series[0].type === 'pie') {
        options.series[0].strokes = ['#ffffff'];
        options.series[0].strokeWidth = 2; 
        options.series[0].calloutLabelKey = options.series[0].labelKey;
        options.series[0].angleName = options.series[0].title?.text;
        delete options.series[0].labelKey;
        delete options.series[0].xKey; 
        delete options.series[0].yKeys; 
        delete options.series[0].xName;
        delete options.series[0].yNames;
        delete options.series[0].grouped;
        delete options.series[0].yKey; 
        delete options.series[0].yName;
      }
      
      if (options.series[0].type === 'area') {
        delete options.series[0].yKey; 
        delete options.series[0].yName;
        delete options.series[0].angleKey;
        delete options.series[0].labelKey;
        delete options.series[0].sizeKey;
        delete options.series[0].sizeName;
        if (!options.series[0].grouped) {
          options.series[0].stacked = true;
        }
        else {
          options.series[0].fillOpacity = 0.7;
          options.series[0].stacked = false;
        }
        delete options.series[0].grouped;
      }
      
      if (options.series[0].type === 'bar') {
        delete options.series[0].xName;
        // delete options.series[0].yKey; 
        // delete options.series[0].yName;
        delete options.series[0].angleKey;
        delete options.series[0].labelKey;
      }
      
      if (options.series[0].type === 'histogram') {
        delete options.series[0].yKeys; 
        delete options.series[0].yNames;
        delete options.series[0].yKey; 
        delete options.series[0].yName;
        delete options.series[0].angleKey;
        delete options.series[0].labelKey;
        delete options.series[0].grouped;
      }
      
      for (var series of chart.series) {
        if (series.xKey && series.xKey.constructor === Array) {
          if (series.xKey.length > 1) {
            series.xKey = chart.series[0].xKey.join(' ');
            chart.axes[0].keys[0] = series.xKey;
            chart.axes[0].type = 'category';
          }
          else {
            series.xKey = series.xKey[0];
          }
        }
        
        if (series.sizeKey && series.sizeKey.constructor === Array) {
          series.sizeKey = series.sizeKey[0];
        }
      }
      
      if (chart.series.length > 0 && chart.series[0].type === 'bubble') {
        // options.series[0].type = 'scatter';
        options.series[0].marker = {
          size: 100,
          minSize: 5,
          fillOpacity: 0.5
        };
      }
      
      if (chart.series.length > 0 && 
          chart.series[0].type !== 'pie' &&
          chart.series[0].type !== 'doughnut') {
        for (const axe of chart.axes) {
          if (axe.keys.length > 0 &&
              axe.keys[0].includes('progress')) {
            axe.max = 100;
            axe.min = 0;
          }
        }
        options.axes = chart.axes;
      }
    
      if (options.series.length > 0) {
        options.series[0].fills = this.fills;
        options.series[0].strokes = this.strokes;
      }
      options = this.addFormatting(options, chart);
      this.convertDataForAgChartV9(options);
      this.formatTitleAndSubTitleStyle(options);
      if (chart.display_title === false) {
        delete options.title;
      }
      
      // fixing undefined value on the scatter chart markers
      if (options.series.length > 0 &&
          options.series[0].type === 'scatter') {
        options.series[0].labelKey = options.series[0].labelKey.replace(/\./g, '|')
      }
      
      self.options = options;
    },
    processTimeSeriesData(chart, data, self) {
      const preparedData = data;
      self.totalRecords = (chart.axes[1].keys.length * self.epochs.length) / 2;
      if (self.totalRecords > 600) {
        self.totalRecords = 600; // cap the total to prevent the width of the chart exceeding 10000px
      }
      
      let options = { 
        data: preparedData,
        background: {
          fill: cColors.getThemeColor('surface-bg')
        },
        title: {
          text: chart.name,
          fontSize: 18,
          fontFamily: 'Roboto',
          color: cColors.getThemeColor('text-dark')
        },
        subtitle: { 
          text: chart.description,
          fontFamily: 'Roboto',
          color: cColors.getThemeColor('text-light')
        },
        series: chart.series
      };
      
      if (typeof chart.legend !== 'undefined') {
        options.legend = chart.legend;
        //options.legend.fontFamily = 'Roboto';
        //options.legend.markerShape = 'circle';
        //options.legend.strokeWidth = 0;
        options.legend.item = { label: { color: cColors.getThemeColor('text-medium')}}
      }
      
      if (chart.series.length > 0 && chart.series[0].type === 'doughnut') {
        options.series[0].innerRadiusOffset = -150;
        options.series[0].type = 'pie';
      }
      
      if (options.series[0].type === 'pie') {
        options.series[0].strokes = ['#ffffff'];
        options.series[0].strokeWidth = 2; 
      }
      
      if (options.series[0].type === 'area') {
        for (let i = 0; i < options.series.length; i++) {
          delete options.series[i].yKey; 
          delete options.series[i].yName;
          delete options.series[i].angleKey;
          delete options.series[i].labelKey;
          delete options.series[i].sizeKey;
          delete options.series[i].sizeName;
          if (!options.series[i].grouped) {
            options.series[i].stacked = true;
          }
          else {
            options.series[i].fillOpacity = 0.7;
          }
          delete options.series[i].grouped;
        }
      }
      
      for (var series of chart.series) {
        if (series.xKey.constructor === Array) {
          if (series.xKey.length > 1) {
            series.xKey = chart.series[0].xKey.join(' ');
            chart.axes[0].keys[0] = series.xKey;
            chart.axes[0].type = 'category';
          }
          // column and bar charts do not support time type axis
          else if (chart.series[0].type === 'column' ||
                   chart.series[0].type === 'bar' ||
                   chart.series[0].type === 'area') {
            series.xKey = 'epoch';
            series.xName = 'Time';
            chart.axes[0].keys[0] = 'epoch';
            chart.axes[0].type = 'category';
            chart.axes[0].title.text = this.$t('dataview.chart.time');
          }
          else {
      
            // set the time series
            series.xKey = 'epoch';//series.xKey[0];
            series.xName = 'Time';
            chart.axes[0].keys[0] = 'epoch';
            chart.axes[0].type = 'category';
            chart.axes[0].nice = false;
            chart.axes[0].title.text = this.$t('dataview.time');
            // chart.axes[0].tick = {
            //   count: this.getTimeTick(),
            // };
            
            if (!chart.axes[0].label) {
              chart.axes[0].label = {
                format: this.getAxisFormat(),
              };  
            }
            else {
              chart.axes[0].label.format = this.getAxisFormat();
            }
            
            // Add theme colors
            chart.axes[0].label.color = cColors.getThemeColor('text-medium');
            // chart.axes[0].gridStyle = [{ stroke: cColors.getThemeColor('axis-stroke'), lineDash: [0, 0] }];
            chart.axes[0].gridLine = { style: [{ stroke: cColors.getThemeColor('axis-stroke'), lineDash: [0, 0] }] };
                      
          }
        }
        
        if (series.sizeKey && series.sizeKey.constructor === Array) {
          series.sizeKey = series.sizeKey[0];
        }
        
        if (series.type === 'bubble') {
          // series.type = 'scatter';
          series.marker = {
            size: 100,
            minSize: 5,
            fillOpacity: 0.5
          };
        }
      }
      
      
      if (chart.series.length > 0 && 
          chart.series[0].type !== 'pie' &&
          chart.series[0].type !== 'doughnut') {
        for (const axe of chart.axes) {
          if (axe.keys[0].includes('progress')) {
            axe.max = 100;
            axe.min = 0;
          }
        }
        options.series[0].fills = this.mdColors;
        options.series[0].strokes = this.mdColors;
        options.axes = chart.axes;
      }
    
      if (options.series.length > 0) {
        options.series[0].fills = this.fills;
        options.series[0].strokes = this.strokes;
      }
      
      options = this.addFormatting(options, chart);
      this.convertDataForAgChartV9(options);
      this.formatTitleAndSubTitleStyle(options);
      if (chart.display_title === false) {
        delete options.title;
      }
      self.options = options;
    },
    getTimeTick() {
      
      if (this.span === 'Daily') {
        return agCharts.time.day;
      }
      else if (this.span === 'Weekly') {
        return agCharts.time.day.every(7);
      }
      else if (this.span === 'Monthly') {
        return agCharts.time.month;
      }
      else if (this.span === 'Yearly') {
        return agCharts.time.year;
      }
      return null;
    },
    getAxisFormat() {
      if (this.span === 'Daily') {
        return 'DD/MM';
      }
      if (this.span === 'Weekly') {
        return 'DD/MM';
      }
      if (this.span === 'Monthly') {
        return 'MM/YYYY';
      }
      if (this.span === 'Yearly') {
        return 'YYYY';
      }
    },
    formatEpoch(epoch) {
      const dt = new Date(epoch);
      if (this.span === 'Daily') {
        return getShortDate(dt, 'date');
      }
      if (this.span === 'Weekly') {
        return getShortDate(dt, 'date');
      }
      if (this.span === 'Monthly') {
        return getShortDate(dt, 'month');
      }
      if (this.span === 'Yearly') {
        return getShortDate(dt, 'year');
      }
      return dt;
    },
    editChart() {
      this.$emit('editChart');
    },
    removeChart() {
      this.$emit('removeChart');
    },
    chartExport() {
      this.$emit('chartExport');
    },
    async rangeSelected() {
      this.dates = this.datesStr == 'null' ? null : this.datesStr;
      this.datesChanged();
      this.chartObj.span = this.span;
      this.chartObj.startDate = this.startDate;
      this.chartObj.endDate = this.endDate;
      this.chartObj.dates = this.dates;
      this.updateChart(); 
    },
    updateChart() {
      this.$emit('updateChart', this.chartObj);
      this.options = null;
      this.loadChartData(this.chartObj);
    },
    async daySelected() {
      const self = this;
      let start = new Date(self.startDate);
      const end = new Date(self.endDate);
      if (end < start) {
        // do not allow start to be greater than end
        if (self.span === 'Daily') {
          start.setDate(start.getDate() + 1);
        }
        else if (self.span === 'Weekly') {
          start.setDate(start.getDate() + 7);
        }
        else if (self.span === 'Monthly') {
          const month = start.getMonth();
          start.setMonth(month + 1);
        }
        else if (self.span === 'Yearly') {
          const year = start.getFullYear();
          start.setYear(year + 1);
        }
        self.$nextTick(() => {
          self.$set(self, 'endDate', start.toISOString().split('T')[0]);
        });
        return;
      }
      const epochResult = calcEpochs(this.span, this.startDate, this.endDate);
      this.epochs = epochResult.epochs;
      if (this.epoch && this.epochs.length > 0) {
        if (!this.epochs.includes(this.epoch)) {
          this.epoch = this.epochs[0];
        }
      }
      else {
        this.epoch = null;
        this.datesMode = "timeseries";
      }
      this.dates = null;
      this.datesStr = 'null';
      this.chartObj.span = this.span;
      this.chartObj.startDate = this.startDate;
      this.chartObj.endDate = this.endDate;
      this.chartObj.dates = this.dates;
      this.$emit('updateChart', this.chartObj);
      this.updateChart(); 
    },
    async updateSpan() {
      const epochResult = calcEpochs(this.span, this.startDate, this.endDate);
      this.epochs = epochResult.epochs;
      if (this.epoch && this.epochs.length > 0) {
        if (!this.epochs.includes(this.epoch)) {
          this.epoch = this.epochs[0];
        }
      }
      else {
        this.epoch = null;
        this.datesMode = "timeseries";
      }
      this.chartObj.span = this.span;
      this.chartObj.startDate = this.startDate;
      this.chartObj.endDate = this.endDate;
      this.chartObj.dates = this.dates;
      this.$emit('updateChart', this.chartObj);
      this.updateChart(); 
    },
    datesChanged() {
      const self = this;
      this.datesChanging = true;
      if (this.dates === "project-start-to-end") {
        if (this.project === null || typeof this.projectData.scheduleStart === 'undefined' || typeof this.projectData.scheduleFinish === 'undefined') {
          this.dates = null;
          this.datesStr = 'null';
          let today = new Date();
          this.startDate = formatDate(today);
          today.setDate(today.getDate() + 7);
          this.endDate = formatDate(today);
        }
        else {
          this.startDate = formatDate(new Date(this.projectData.scheduleStart));
          this.endDate = formatDate(new Date(this.projectData.scheduleFinish));
        }
      } 
      else if (this.dates === "project-schedule") {
        if (this.min === 0 || this.max === 0 ||
            this.min === null || this.max === null ||
            this.min === 'NaN' || this.max === 'NaN') {
          this.dates = null;
          this.datesStr = 'null';
          let today = new Date();
          this.startDate = formatDate(today);
          today.setDate(today.getDate() + 7);
          this.endDate = formatDate(today);
        }
        else {
          // make sure the span is less than 3 years
          const diff = this.max - this.min;
          if (diff > 94670778000) {
            this.max = this.min + (94670778000 - 86400000);
          }
          this.startDate = formatDate(new Date(this.min));
          this.endDate = formatDate(new Date(this.max));
        }
      } 
      else if (this.dates === "this-week") {
        let today = getNextWorkingDay(new Date());
        const day = today.getDay();
        if (day !== 1) {
          if (day === 0) {
            today.setDate(today.getDate() + 1); // Monday
          }
          else {
            today.setDate(today.getDate() - (day - 1)); // Monday
          }
        }
        this.startDate = formatDate(today);
        
        // Sunday
        today.setDate(today.getDate() + 6); 
        this.endDate = formatDate(today);
      } 
      else if (this.dates === "this-week-to-date") {
        let today = getNextWorkingDay(new Date());
        this.endDate = formatDate(today);
        
        const day = today.getDay();
        if (day !== 1) {
          if (day === 0) {
            today.setDate(today.getDate() + 1); // Monday
          }
          else {
            today.setDate(today.getDate() - (day - 1)); // Monday
          }
        }
        this.startDate = formatDate(today);
      } 
      else if (this.dates === "this-month") {
        let today = new Date();
        this.endDate = formatDate(new Date(today.getFullYear(), today.getMonth() + 1, 0));
        
        this.startDate = formatDate(new Date(today.getFullYear(), today.getMonth(), 1));
      } 
      else if (this.dates === "this-month-to-date") {
        let today = new Date();
        this.endDate = formatDate(today);
        
        this.startDate = formatDate(new Date(today.getFullYear(), today.getMonth(), 1));
      } 
      else if (this.dates === "this-quarter") {
        let today = new Date();
        let quarter = Math.floor((today.getMonth()) / 3);
        
        let thisq;
        if (quarter == 4) {
            thisq = new Date (today.getFullYear(), 9, 1); // start in October
        } else {
            thisq = new Date (today.getFullYear(), quarter * 3, 1);
        }
        this.startDate = formatDate(thisq);
        const lastday = new Date(thisq.getFullYear(), thisq.getMonth() + 3, 0);
        this.endDate = formatDate(lastday);
      } 
      else if (this.dates === "this-quarter-to-date") {
        let today = new Date();
        let quarter = Math.floor((today.getMonth()) / 3);
        
        let thisq;
        if (quarter == 4) {
            thisq = new Date (today.getFullYear(), 9, 1); // start in October
        } else {
            thisq = new Date (today.getFullYear(), quarter * 3, 1);
        }
        this.startDate = formatDate(thisq);
        this.endDate = formatDate(today);
      } 
      else if (this.dates === "this-year") {
        let today = new Date();
        const year = today.getFullYear();
        this.startDate = [year, '01', '01'].join('-');
        this.endDate = [year, '12', '31'].join('-');
      } 
      else if (this.dates === "this-year-to-date") {
        let today = new Date();
        this.endDate = formatDate(today);
        const year = today.getFullYear();
        this.startDate = [year, '01', '01'].join('-');
      } 
      else if (this.dates === "last-week") {
        let today = new Date();
             
        const day = today.getDay();
        today.setDate(today.getDate() - day); // Sunday
        this.endDate = formatDate(today);
        
        today.setDate(today.getDate() - 6); // Monday
        this.startDate = formatDate(today);
      } 
      else if (this.dates === "last-week-to-date") {
        let today = new Date();
        this.endDate = formatDate(today);
        const day = today.getDay();
        today.setDate(today.getDate() - day); // Sunday
        today.setDate(today.getDate() - 6); // Monday
        this.startDate = formatDate(today);
      } 
      else if (this.dates === "last-month") {
        let today = new Date();
        this.endDate = formatDate(new Date (today.getFullYear(), today.getMonth(), 0));        
        this.startDate = formatDate(new Date (today.getFullYear(), today.getMonth() - 1, 1));
      } 
      else if (this.dates === "last-month-to-date") {
        let today = new Date();
        this.endDate = formatDate(today);
            
        this.startDate = formatDate(new Date (today.getFullYear(), today.getMonth() - 1, 1));
      } 
      else if (this.dates === "last-quarter") {
        let today = new Date();
        let quarter = Math.floor((today.getMonth()) / 3);
        quarter > 0 ? quarter-- : quarter = 4;
        
        let lastq;
        if (quarter == 4) {
            lastq = new Date (today.getFullYear() - 1, 9, 1); // start in October
        } else {
            lastq = new Date (today.getFullYear(), quarter * 3, 1);
        }
        this.startDate = formatDate(lastq);
        this.endDate = formatDate(new Date (today.getFullYear(), lastq.getMonth() + 3, 0));
      } 
      else if (this.dates === "last-quarter-to-date") {
        let today = new Date();
        let quarter = Math.floor((today.getMonth()) / 3);
        quarter > 0 ? quarter-- : quarter = 4;
        
        let lastq;
        if (quarter == 4) {
            lastq = new Date (today.getFullYear() - 1, 9, 1); // start in October
        } else {
            lastq = new Date (today.getFullYear(), quarter * 3, 1);
        }
        this.startDate = formatDate(lastq);
        this.endDate = formatDate(today);
      } 
      else if (this.dates === "last-year") {
        const b = new Date();
        b.setFullYear(b.getFullYear() - 1);
        const year = b.getFullYear();
        this.startDate = [year, '01', '01'].join('-');
        this.endDate = [year, '12', '31'].join('-');
      } 
      else if (this.dates === "next-week") {
        let today = getNextWorkingDay(new Date());
        const day = today.getDay();
        
        today.setDate(today.getDate() + (8 - day)); // Monday
        this.startDate = formatDate(today);
        today.setDate(today.getDate() + 6); // Sunday
        this.endDate = formatDate(today);
      } 
      else if (this.dates === "next-4-weeks") {
        let today = getNextWorkingDay(new Date());
        const day = today.getDay();
        
        today.setDate(today.getDate() - (day - 1)); // Monday
        this.startDate = formatDate(today);
        today.setDate((today.getDate() + 4 * 7) - 1);
        this.endDate = formatDate(today);
      } 
      else if (this.dates === "next-month") {
        let today = new Date();
        const nextm = new Date(today.getFullYear(), today.getMonth() + 1, 1);
        this.startDate = formatDate(nextm);
        const lastday = new Date(nextm.getFullYear(), nextm.getMonth() + 1, 0);
        this.endDate = formatDate(lastday);
      } 
      else if (this.dates === "next-quarter") {
        let today = new Date();
        let quarter = Math.floor((today.getMonth() + 3) / 3);
        var nextq;
        if (quarter == 4) {
            nextq = new Date (today.getFullYear() + 1, 1, 1);
        } else {
            nextq = new Date (today.getFullYear(), quarter * 3, 1);
        }
        this.startDate = formatDate(nextq);
        const lastday = new Date(nextq.getFullYear(), nextq.getMonth() + 3, 0);
        this.endDate = formatDate(lastday);
      } 
      else if (this.dates === "next-year") {
        const b = new Date();
        b.setFullYear(b.getFullYear() + 1);
        const year = b.getFullYear();
        this.startDate = [year, '01', '01'].join('-');
        this.endDate = [year, '12', '31'].join('-');
      }
      Vue.nextTick(() => {
        self.datesChanging = false;
      });
      
      if (!self.span) {
        self.span = 'Daily';
      }
      
      self.chart.span = self.span;
      self.chart.startDate = self.startDate;
      self.chart.endDate = self.endDate;      
      if (self.chart.dates !== self.dates) {
        // selection has changed, update the selected epoch
        if (self.epoch !== null) {
          const epochResult = calcEpochs(self.span, self.startDate, self.endDate);
          self.chart.epoch = epochResult.epochs.length > 0 ? epochResult.epochs[0] : null;
        }
      }
      self.chart.dates = self.dates;
      this.$emit('updateChart', this.chartObj);
    },
    numberFields(fields /**, selected */) {
      this.numberFieldOptions = [];
      for (var field of fields) {
        var type = getFieldType(field, this.schema);
        
        if (type === 'Cost' ||
            type === 'Duration' ||
            type === 'MinuteDuration' ||
            type === 'Progress' ||
            type === 'Integer' ||
            type === 'Long'
            || type === 'Double' ||
            type === 'Float') {
          this.numberFieldOptions.push({ value: field, text: this.dataview.fieldNames[field] });
        }
        else if (typeof field === 'object' && field.agFunction !== null) {
          this.numberFieldOptions.push({ value: `${field.agFunction.toUpperCase()}(${field.field})`, text: field.name });
        }
      }
    },
    populateFieldOptions(fields, selected) {
      this.fieldOptions = [];
      for (let idx = 0; idx < fields.length; idx++) {
        const value = typeof fields[idx] === 'string' ? fields[idx] : `${fields[idx].agFunction.toUpperCase()}(${fields[idx].field})`
        this.fieldOptions.push({ value: value, text: this.dataview.fieldNames[value] });
      }
      
      if (this.timeseries_field === null) {
        this.timeseries_field = selected;
      }
      
      if (this.timeseries_label === null) {
        // only set string types as this is used to get the value from the data
        for (const field of fields) {
          if (typeof field === 'string') {
            this.timeseries_label = field;
            break;
          }
        }
      }
      
      if (this.timeseries_field === null) {
        // only set string types as this is used to get the value from the data
        for (const field of fields) {
          if (typeof field === 'string' &&
              getFieldType(field, this.schema) !== 'String') {
            this.timeseries_field = field;
            break;
          }
        }
      }
    },
    async onCurrent() {
      this.datesMode = 'current'; 
      this.datesChanged();
      this.chartObj.span = this.span;
      this.chartObj.startDate = this.startDate;
      this.chartObj.endDate = this.endDate;
      this.chartObj.dates = this.dates;
      this.chartObj.datesMode = this.datesMode;
      this.chartObj.epoch = null;
      this.updateChart(); 
    },
    async onActuals() {
      const self = this;
      this.datesMode = 'actuals';
      this.chartObj.datesMode = this.datesMode;
      
      const epochResult = calcEpochs(self.span, self.startDate, self.endDate);
      self.epochs = epochResult.epochs;
      self.epochOptions = epochResult.epochOptions;
      this.epoch = this.epochs.length !== 0 ? this.epochs[0] : null;
      this.chartObj.epoch = this.epoch;
      
      if (!this.dates && !this.startDate && !this.endDate) {
        this.dates = this.datesStr = "this-week";
        this.datesChanged();
      }
      this.updateChart();
    },
    onTimeSeries() {
      this.datesMode = 'timeseries';
      this.chartObj.datesMode = this.datesMode;
      
      this.epoch = null;
      this.chartObj.epoch = this.epoch;
      
      if (!this.dates && !this.startDate && !this.endDate) {
        this.dates = this.datesStr = "this-week";
        this.datesChanged();
      }
      this.updateChart();
    },
    fieldSelected(field) {
      this.chartObj.timeseries_field = this.timeseries_field;
      this.chartObj.axes[1].keys[0] = this.timeseries_field;
      this.chartObj.series[0].yKey = this.timeseries_field;
      this.chartObj.series[0].yKeys = [this.timeseries_field];
      const name = this.numberFieldOptions.filter(n => n.value === field)[0].text;
      this.chartObj.series[0].yName = name;
      this.chartObj.series[0].yNames = [name];
      this.chartObj.axes[1].title.text = name;
      this.updateChart();
    },
    labelSelected() {
      this.chartObj.timeseries_label = this.timeseries_label;
      this.updateChart();
    },
    leftEpoch() {
      const index = this.epochs.findIndex(e => e === this.epoch);
      if (index !== 0) {
        this.chartObj.epoch = this.epoch = this.epochs[index - 1];
        this.updateChart();
      }
    },
    rightEpoch() {
      const index = this.epochs.findIndex(e => e === this.epoch);
      if (index !== this.epochs.length - 1) {
        this.chartObj.epoch = this.epoch = this.epochs[index + 1];
        this.updateChart();
      }
    },
    epochSelected() {
      this.updateChart();
    },
    toggleEpoch() {
      this.useEpoch = !this.useEpoch;
      if (!this.useEpoch &&
          !this.timeseries_hint_donotshow) {
        this.timeseriesHintShow = true;    
      }
      this.chartObj.useEpoch = this.useEpoch;
      this.updateChart();
    },
    addSeries(chart, key, name) {
      const self = this;
      const filter = chart.axes[1].keys.filter(k => k === key);
      if (key === '' ||
          filter.length !== 0) {
        return chart; // only add it once
      }
      
      const series = cloneDeep(chart.series[0]);
      chart.axes[1].keys.push(key);
      if (series.type === 'area' ||
          series.type === 'bubble') {
        if (series.yKeys) {
          series.yKeys.push(key);
        }
        if (series.yNames) {
          series.yNames.push(name);
        }
        
        series.tooltip = {
          renderer: function (params) {
            if (params.xValue instanceof Date) {
              return {
                content: params.yValue,
                title: `${params.yName}: ${moment(params.xValue).format(self.getAxisFormat())}`
              }
            }
            else if (self.datesMode === 'timeseries') {
              return {
                  content: params.yValue,
                  title: `${params.yName}: ${params.xValue}`
              };
            }
            return {
                content: params.yValue,
                title: params.xValue
            };
          }
        }
      }
      else if (series.type === 'scatter' ||
               series.type === 'line') {
        series.marker = { fill: series.fills[0]};
        delete series.fills;
        series.marker = { stroke: series.strokes[0]};
        // series.tooltip = {
        //   renderer: function (params) {
        //     if (params.xValue instanceof Date) {
        //       return {
        //         content: params.yValue,
        //         title: `${params.yName}: ${moment(params.xValue).format(self.getAxisFormat())}`
        //       }
        //     }
        //     else if (self.datesMode === 'timeseries') {
        //       return {
        //           content: params.yValue,
        //           title: `${params.yName}: ${params.xValue}`
        //       };
        //     }
        //     return {
        //         content: params.yValue,
        //         title: params.xValue
        //     };
        //   }
        // }
        delete series.strokes;
        series.yKey = key;
        series.yName = name;
        delete series.yKeys;
        delete series.yNames;
        delete series.grouped;
      }
      else {
        series.yKey = key;
        series.yName = name;
        if (series.yKeys) {
          series.yKeys.push(key);
        }
        if (series.yNames) {
          series.yNames.push(name);
        }
      }
      
      chart.series.push(series);
      return chart;
    },
    onTimeModeOver() {
      this.$refs.timeMode.visible = true;
    },
    onTimeModeLeave() {
      this.$refs.timeMode.visible = false;
    },
    isTouchDevice() {
      const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
      const mq = function (query) {
          return window.matchMedia(query).matches;
      }
      if ('ontouchstart' in window) {
          return true;
      }
      const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
      return mq(query);
    },
    replaceNow(origFilter, epoch) {
      let filter = cloneDeep(origFilter);
      for (var f = 0; f < filter.length; f++) {
        if (typeof filter[f] === 'object') {
          filter[f] = this.replaceNow(filter[f], epoch);
        }
        else if (filter[f] === '<NOW>' &&
            epoch) {
          filter[f] = epoch;
        }
      }
      return filter;
    },
    getDurationConversionOpts() {
      return this.$store.dispatch('data/configSchedule').then(value => {
        this.durationConversionOpts = extractDurationConversionOpts(value);
      })
      .catch(e => {
        console.error(e); //eslint-disable-line no-console
      });
    },
    processFieldsForGroup(fields) {
      if (this.nominate === 'group-by') {
        for (let i = 0; i < fields.length; i++) {
          const field = fields[i];
          if (typeof field === 'object' &&
              field[0] === this.chart.groupfield) {
             field[0] = `${field[0].substr(0, field[0].lastIndexOf('.'))}.uuId`;   
          }
        }
      }
      return fields;
    },
    convertDataForAgChartV9(options) {
      //Format data to fit Ag Chart v9.0.0 data format
      //==============================================
      //
      //1. Deprecated 'column' type. Use bar instead.
      //Old version has 'column' and 'bar' type.
      //New version (v9.0.0) deprecates 'column' type. Only 'bar' type is available.
      //  -When use 'bar' type, 
      //  -Set direction property in series item with 'horizontal' value for horizontal direction. 
      //  -If not provided, assume vertical direction (column) by default.
      //More details: https://charts.ag-grid.com/archive/9.0.1/vue/bar-series/#horizontal-bar
      //New Version (v9.0.0) deprecates 'doughnut' type. Only 'pie' type is available.
      //  - Replacement: For 'doughnut', use 'pie' with 'innerRadiusRatio' property.
      //More details: https://charts.ag-grid.com/archive/9.0.1/vue/doughnut-series/

      //2. Deprecated series item properties (Affected type: Area, bar)
      //Old version allows a series item containing yKeys, yNames, fills, strokes. They all are array objects.
      //New version (v9.0.0) deprecates the yKeys, yNames, fills, strokes property. Use yKey, yName, fill, stroke respectively instead.
      //Solution: populate new series item for each of value in the yKeys. 
      //          Get the respective value from yNames, fills, and strokes (if available) for the new series item.

      //3. Data type of xKey is expected to be string instead of an array.

      
      //Setup tooltip renderer with value formatter
      const formatValue = (key, value) => { return formatFieldValue(key, value, this.schema, { durationFunc: (v) => `${v}D`, percentageFunc: (v) => `${v}%` }, this.durationConversionOpts) }

      //Defensive code
      if (!Array.isArray(options.series)) {
        options.series = []
      }

      const affectedTypes = ['column', 'bar', 'line', 'bubble','area']
      if (options.series.length > 0 && affectedTypes.includes(options.series[0].type)) {
        const newSeries = [];
        for (const seriesItem of options.series) {
          //1. Deprecated 'column' type. Use bar instead.
          if (seriesItem.type == 'column') {
            seriesItem.type = 'bar';
            if (Array.isArray(seriesItem.xKey)) {
              seriesItem.xKey = seriesItem.xKey.length > 0? seriesItem.xKey[0] : ''; //3. Data type of xKey is expected to be string instead of an array.
            }
          } else if (seriesItem.type == 'bar') {
            seriesItem.direction = 'horizontal';
          }

          //Reinstate marker fill for line, scatter, area.
          if (seriesItem.type == 'line' || seriesItem.type == 'scatter' || seriesItem.type == 'area') {
            if (seriesItem.stroke != null) {
              if (seriesItem.marker == null) {
                seriesItem.marker = {
                  fill: seriesItem.stroke
                }
              } else {
                seriesItem.marker.fill = seriesItem.stroke;
              }
            }
          }

          //Disable label for bubble to match old version chart's behaviour.
          if (seriesItem.type == 'bubble') {
            delete seriesItem.label;
          }
          
          //2. Deprecated series item properties 
          if (Array.isArray(seriesItem.yKeys) && seriesItem.yKeys.length > 0) {
            const seriesItemTemplate = JSON.parse(JSON.stringify(seriesItem));
            const yKeys = seriesItemTemplate.yKeys;
            const yNames = seriesItemTemplate.yNames != null? seriesItemTemplate.yNames : [];
            const fills = seriesItemTemplate.fills != null? seriesItemTemplate.fills : [];
            const strokes = seriesItemTemplate.strokes != null? seriesItemTemplate.fills : [];
            delete seriesItemTemplate.yKeys;
            delete seriesItemTemplate.yNames;
            delete seriesItemTemplate.fills;
            delete seriesItemTemplate.strokes;

            let newItem = null
            for (let i=0, len=yKeys.length; i < len; i++) {
              newItem = { 
                ...seriesItemTemplate
                , yKey: yKeys[i]
              }
              if (yNames.length > i && yNames[i] != null) {
                newItem.yName = yNames[i];
              }
              if (fills.length > i && fills[i] != null) {
                newItem.fill = fills[i];
              }
              if (strokes.length > i && strokes[i] != null) {
                newItem.stroke = strokes[i];
              }
              
              newSeries.push(newItem);
            }
          } else {
            newSeries.push(seriesItem);
          }
        }

        if (newSeries.length > 0) {
          for (let i = 0, len = newSeries.length; i < len; i++) {
            //for area
            if (newSeries[i].type == 'area' && newSeries[i].label != null) {
                //format label value 
                newSeries[i].label.formatter = (params) => {
                return formatValue(params.yKey.replaceAll('|', '.'), params.datum[params.yKey]);
              }
            } 
            //for bubble
            else if (newSeries[i].type == 'bubble') {
              delete newSeries[i].fill;
              delete newSeries[i].stroke;

            }
          }

          options.series = newSeries;
        }
      }

      //Setup tooltip renderer with value formatter
      for (const s of options.series) {
        if (s.type == 'histogram') {
          //Don't set custom tooltip. Use default Ag Chart tooltip.
          delete s.tooltip;
        } else {
          s.tooltip = {
            enabled: true,
            renderer: genericChartTooltipRendererGenerator({ 
              contentValueFormatter: formatValue,
              type: s.type
            })
          }
        }
      }

      //Update for pie, doughnut type
      for (const s of options.series) {
        //Deprecated 'doughnut' type. Use pie with the necessary property instead.
        if (s.type == 'doughnut' || (s.type == 'pie' && typeof s.innerRadiusOffset !== 'undefined')) {
          s.type = 'pie';
          s.innerRadiusRatio = 0.3;
          delete s.innerRadiusOffset; //Remove innerRadiusOffset as it interfere the inner radius rendering.
        }
      }

      //Disable legend for histogram
      if (options.series.length > 0) {
        if (options.series[0].type == 'histogram') {
          delete options.legend;
        } else if (options.series[0].type == 'area') {
          for (let i = 0, len = options.series.length; i < len; i++) {
            if (options.series[i].fill != null) {
              options.series[i].marker = {
                enabled: true,
                fill: options.series[i].fill,
                size: 1
              }
            }
          }
          
        }
      }

      //Config tick for NumberAxis
      if (Array.isArray(options.axes) && options.axes.length > 0) {
        for (let i = 0, len = options.axes.length; i < len; i++) {
          
          if (options.axes[i].type == 'number' && options.axes[i].tick?.minSpacing == null) {
            if (options.axes[i].tick == null) {
              options.axes[i].tick = {}
            }
            options.axes[i].tick.minSpacing = 15
            options.axes[i].tick.maxSpacing = 105
          }
        }
      }


      //Ag-chart v9 doesn't support dot(.) character in yKey or xKey
      //Solution: replace all occurrence of dot character to pipe symbol. Same solution is applied to axes and data object.
      if (options.series.length > 0) {
        for (const seriesItem of options.series) {
          if (seriesItem.yKey != null) {
            seriesItem.yKey = seriesItem.yKey.replaceAll('.', '|');
          }
          if (seriesItem.xKey != null) {
            seriesItem.xKey = seriesItem.xKey.replaceAll('.', '|');
          }
          if (seriesItem.angleKey != null) {
            seriesItem.angleKey = seriesItem.angleKey.replaceAll('.', '|');
          }
          if (seriesItem.calloutLabelKey != null) {
            seriesItem.calloutLabelKey = seriesItem.calloutLabelKey.replaceAll('.', '|');
          }
          if (seriesItem.sizeKey != null) {
            seriesItem.sizeKey = seriesItem.sizeKey.replaceAll('.', '|');
          }
        }

        // Data object
        if (Array.isArray(options.data) && options.data.length > 0) {
          const propKeys = Object.keys(options.data[0]);
          for (let i = 0, len = options.data.length; i < len; i++) {
            const d = options.data[i];
            for (const propKey of propKeys) {
              if (propKey.indexOf('.') == -1) {
                continue;
              }
              const newPropKey = propKey.replaceAll('.', '|');
              d[newPropKey] = d[propKey];
              delete d[propKey];
            }

          }
        }
      }

      if (Array.isArray(options.axes) && options.axes.length > 0) {
        for (const axeItem of options.axes) {
          for (let i = 0, len = axeItem.keys.length; i < len; i++) {
            axeItem.keys[i] = axeItem.keys[i].replaceAll('.', '|');
          }
        }
      }
      
    },
    getNumberFieldOptionLabel(value) {
      return this.numberFieldOptions.find(i => i.value === value)?.text || value;
    },
    getEpochOptionLabel(value) {
      return this.epochOptions.find(i => i.value === value)?.text || value;
    },
    getDateOptionLabel(value) {
      return this.dateOptions.find(i => i.value === value)?.text || value;
    },
    getSpanOptionLabel(value) {
      return this.spanOptions.find(i => i.value === value)?.text || value;
    },
    formatTitleAndSubTitleStyle(options) {
      if (this.isWidget) {
        if (options.title != null) {
          options.title = {
            ...options.title,
            fontSize: 14,
            color: cColors.getThemeColor('text-dark'),
            fontWeight: 'bold',
            fontFamily: 'Roboto',
          }
        }
      }
    },
    dateChanged() {
      this.highlightRefresh = true;
      this.dates = null;
      this.datesStr = 'null';
    }
  },
}
</script>
<style lang="scss">
.chart-container {
  // min-width: 600px;
  overflow: visible;
}

.chart-style {
  // height: 613px !important;
  // min-height: 613px;
  min-height: 400px;
  padding-top: 5px;
  border-left: 1px solid var(--border-medium);
  border-right: 1px solid var(--border-medium);
  border-bottom: 1px solid var(--border-medium);
  margin-bottom: 8px;
}

.widget-chart-style {
  padding-top: 5px;
  border: 1px solid var(--border-medium);
  margin-bottom: 25px;
}

.dataview-action-bar label {
  margin-bottom: 0;
}

.loading {
  height: 360px;
  margin-left: calc(50% - 16px);
  margin-top: calc(25% - 16px);
}

.chart-toolbar .btn.btn-secondary,
.time-toolbar .btn.btn-secondary {
  background-color: transparent;
  border-color: transparent;
  padding: 2px 6px;
  margin: 8px 3px;
  border-radius: 3.2px;
  color: var(--grid-toolbar-button);
}

.active-check {
  position: absolute;
  right: 10px;
}

.time-toolbar ul {
  margin: 0;
}

svg.active {
  color: #E77615;
}

</style>
